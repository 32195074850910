import { formatMessage, getLocale } from "umi";
import { message } from "antd";
import type { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import axios from "axios";
import qs from "qs";

import { uchoiceToken } from "@/utils/auth";
import { getUliveAdminBaseUrl } from "@/utils/getBaseUrl";

// import whiteList from "./whiteList";

const instance: AxiosInstance = axios.create({
  // baseURL: getUliveAdminBaseUrl(), // url = base url + request url
  timeout: 60 * 1000 // request timeout
});

// 用于根据当前请求的信息，生成请求 Key
function generateReqKey(config: AxiosRequestConfig) {
  const { method, url, params, data } = config;
  return [method, url, qs.stringify(params), qs.stringify(data)].join("&");
}

const pendingRequest = new Map();
// 用于把当前请求信息添加到pendingRequest对象中
function addPendingRequest(config: AxiosRequestConfig) {
  const requestKey = generateReqKey(config);
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pendingRequest.has(requestKey)) {
        pendingRequest.set(requestKey, cancel);
      }
    });
}

// 检查是否存在重复请求，若存在则取消已发的请求。
function removePendingRequest(config: AxiosRequestConfig) {
  const requestKey = generateReqKey(config);
  if (pendingRequest.has(requestKey)) {
    const cancelToken = pendingRequest.get(requestKey);
    cancelToken(requestKey);
    pendingRequest.delete(requestKey);
  }
}

// 请求拦截器
instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    removePendingRequest(config); // 检查是否存在重复请求，若存在则取消已发的请求
    addPendingRequest(config); // 把当前请求信息添加到pendingRequest对象中
    if (!config.headers) {
      return;
    }
    config.headers["time-zone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    config.baseURL = getUliveAdminBaseUrl();
    const token = uchoiceToken.get();
    if (localStorage.getItem("Youpick-Ops-Token")) {
      localStorage.setItem("Youpick-Ops-Token", "");
    }
    let auth: string = "";

    if (token) {
      auth = "Bearer " + token;
    } else {
      auth = "Basic " + btoa("webApp:webApp");
    }

    // TODO: 为什么要加这个
    // if (!config.headers.NotToken) {
    //   auth = "";
    // }

    if (auth) {
      config.headers.Authorization = auth;
    }

    config.headers["language"] = getLocale();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    const { data, status, config } = response;
    // console.log(JSON.stringify(data.result), "jiopjuoip");
    // let JSON_result = JSON.stringify(data.result);
    // JSON_result = JSON_result.replaceAll("https", "dpd");
    // console.log(JSON_result.replaceAll("https", "dpd"), "8888");
    // if (JSON_result.includes("https")){
    //   JSON_result = JSON_result.replace("https", "dpd");
    //   console.log(JSON_result, "8888");
    // }
    // data.result = JSON.parse(JSON_result);
    removePendingRequest(config); // 从pendingRequest对象中移除请求s
    if (config.responseType !== "blob") {
      const { message: msg, code, success } = data;
      if (
        code !== 200 &&
        code !== 82013 &&
        code !== 101001 &&
        code !== 82018 &&
        code !== 103018 &&
        !(code === 10300 && success)
      ) {
        message.error(msg);
      }
    }
    return response;
  },
  (error: AxiosError) => {
    removePendingRequest(error.config || {}); // 从pendingRequest对象中移除请求
    if (axios.isCancel(error)) {
      console.log("已取消的重复请求：" + error.message);
      return Promise.resolve({ data: { code: 10086 } });
    }
    // 添加异常处理
    const { response } = error;
    if (response) {
      if (response.status === 401 && !location.host.includes("localhost")) {
        location.href = `${location.origin}/login?redirect=${encodeURIComponent(location.href)}`;
      } else if (response.status === 503) {
        message.error("503 Service Unavailable");
      } else if (response.status !== 200) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        response.data && response.data.message && message.error(response.data.message);
      }
      return response;
    }

    return Promise.reject(error);
  }
);

export default instance;
