import { userInfoStorage } from "./utils/storage";

/**
 * 34495407390742 公用的管理员账号youpik
 * 47017749708844 柳斌账号
 * tapMaster 标记为tap达人的账号
 * tapMasterOrSuperAdmin 标记为tap达人的账号或者超级管理员
 */
export default (initialState: IInitialStateProps) => {
  // 在这里按照初始化数据定义项目中的权限，统一管理
  // 参考文档 https://next.umijs.org/docs/max/access
  console.log("initialState", initialState);
  const currentUser = initialState.currentUser
    ? initialState.currentUser
    : JSON.parse(userInfoStorage.get() || "{}");
  const { id, type, roleId, username } = currentUser;

  return {
    canOporation: id === "34495407390742" || id === "47017749708844", // 超级管理员的账号或者柳斌的账号，目前用在运营管理>投流管理的权限控制
    picPerformance:
      id === "34495407390742" ||
      id === "47017749708844" ||
      id === "57816306155548" ||
      id === "243372308693051" ||
      id === "237788832464960" || // 绩效管理权限
      id === "196469831499821",
    creatorExportPermissions:
      id === "57816306155548" ||
      id === "47017749708844" ||
      id === "34495407390742" ||
      id === "100000000000" ||
      id === "65666967732236" ||
      id === "121268152369192" ||
      id === "196469831499821",
    isSuperAdmin: type === "superAdmin",
    isShowActivityStatus:
      username == "Nancy" ||
      username == "Hana" ||
      username == "Ana" ||
      username == "Denise" ||
      type === "superAdmin",
    tapMaster: roleId === 1 || roleId === 3, // roleId 1达人运营 2商家运营 3达人运营和商家运营
    tapMasterOrSuperAdmin: roleId === 1 || roleId === 3 || type === "superAdmin",
    merchantOperationsOrSuperAdmin: roleId === 2 || roleId === 3 || type === "superAdmin",
    creatorPic: roleId === 1, // 达人运营
    shopPic: roleId === 2, // 商家运营
    creatorAndShopPic: roleId === 3 // 3达人运营和商家运营
  };
};
