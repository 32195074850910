export default {
  通知方式: "通知方式",
  APP推送: "APP推送",
  标题: "标题",
  消息内容: "消息内容",
  打开跳转至: "打开跳转至",
  橱窗检测: "橱窗检测",
  数据榜单首页: "数据榜单首页",
  AI话题首页: "AI话题首页",
  AI定制: "AI定制",
  待发送: "待发送",
  已发送: "已发送",
  发送失败: "发送失败",
  推送时间: "推送时间",
  接收人: "接收人",
  所有用户: "所有用户",
  创建人: "创建人",
  查看自定义推送: "查看自定义推送",
  编辑自定义推送: "编辑自定义推送",
  创建自定义推送: "创建自定义推送",
  全部用户: "全部用户",
  指定用户: "指定用户",
  定时发送: "定时发送",
  即刻发送: "即刻发送",
  内容分类: "内容分类",
  通知: "通知",
  推荐: "推荐",
  频次: "频次",
  仅一次: "仅一次",
  每n天一次: "每 {day} 天一次",
  通知名称: "通知名称",
  触发节点: "触发节点",
  待审核转为待发货: "待审核转为待发货",
  待发货转为已发货: "待发货转为已发货",
  待发货转为待履约: "待发货转为待履约",
  橱窗账号登录状态异常: "橱窗账号登录状态异常",
  存在橱窗高佣待替换商品: "存在橱窗高佣待替换商品",
  没有橱窗登录过的用户: "没有橱窗登录过的用户",
  没有开启自动替换高佣的用户: "没有开启自动替换高佣的用户",
  未点击过加入推广计划按钮的用户: "未点击过加入推广计划按钮的用户",
  "榜单推荐-U选爆品": "榜单推荐-U选爆品",
  "榜单推荐-达人带货榜": "榜单推荐-达人带货榜",
  "榜单推荐-U选高佣": "榜单推荐-U选高佣",
  "榜单推荐-AI热点主题榜": "榜单推荐-AI热点主题榜",
  "榜单推荐-持续好货榜": "榜单推荐-持续好货榜",
  "榜单推荐-达人涨粉榜": "榜单推荐-达人涨粉榜",
  "榜单推荐-日销商品榜": "榜单推荐-日销商品榜",
  "榜单推荐-运营主推tap": "榜单推荐-运营主推tap",
  所有用户包括访客: "所有用户包括访客",
  申样用户: "申样用户",
  橱窗授权用户: "橱窗授权用户",
  已登录过橱窗的用户: "已登录过橱窗的用户",
  默认跳转: "默认跳转",
  指定H5页面: "指定H5页面",
  APP内页面: "APP内页面",
  单商品: "单商品",
  待发货订单列表: "待发货订单列表",
  待履约订单列表: "待履约订单列表",
  橱窗检测账号列表: "橱窗检测账号列表",
  高佣可替换商品列表: "高佣可替换商品列表",
  APP指定商品集落地页: "APP指定商品集落地页",
  APP数据持续好货榜: "APP数据持续好货榜",
  编辑内容: "编辑内容",
  确认关闭推送消息: "确认关闭推送消息",
  确认修改推送内容: "确认修改推送内容",
  "确认后内容将生效，你还要继续吗？":
    "<span style='color: red'>确认后内容将生效，</span>你还要继续吗？",
  正在编辑推送内容: "正在编辑推送内容",
  推送标题: "推送标题",
  请输入至少五个字符: "请输入至少五个字符",
  "确认后将不再推送此条消息，你还要继续吗？":
    "<span style='color: red'>确认后将不再推送此条消息，</span>你还要继续吗？",
  关闭推送: "关闭推送",
  开启推送: "开启推送",
  "TAP榜单-免费样品": "TAP榜单-免费样品",
  "TAP榜单-收藏夹": "TAP榜单-收藏夹",
  "TAP榜单-添加橱窗": "TAP榜单-添加橱窗",
  "TAP榜单- 30天畅销榜单": "TAP榜单- 30天畅销榜单",
  当天有订单审核被拒且无通过的: "当天有订单审核被拒且无通过的",
  当天有账号升级变动: "当天有账号升级变动",
  L0账号触发给样机制: "L0账号触发给样机制",
  新品到货: "新品到货",
  推广记录建议: "推广记录建议",
  每日6款推荐: "每日6款推荐",
  L0等级用户: "L0等级用户",
  过去30天有推广记录的用户: "过去30天有推广记录的用户",
  有授权记录的用户: "有授权记录的用户",
  过去7天有推广记录的用户: "过去7天有推广记录的用户",
  过去7天无推广记录的用户: "过去7天无推广记录的用户",
  所有已登录用户: "所有已登录用户",
  "推广记录建议--近7天有数据": "推广记录建议--近7天有数据",
  "推广记录建议--近7天无数据": "推广记录建议--近7天无数据",
  "爆单提醒-用户当天销量大于30单": "爆单提醒-用户当天销量大于30单",
  "TikTok数据榜单-店铺榜": "TikTok数据榜单-店铺榜",
  "TikTok数据榜单-潜力爆品榜": "TikTok数据榜单-潜力爆品榜",
  推送状态: "推送状态",
  已开启: "已开启",
  即将开启: "即将开启",
  每周一: "每周一",
  每周二: "每周二",
  每周三: "每周三",
  每周四: "每周四",
  每周五: "每周五",
  每周六: "每周六",
  每周日: "每周日",
  推送名称: "推送名称",
  等级用户: "等级用户",
  跳转页面: "跳转页面",
  时间重合: "时间重合",
  确认开启: "确认开启",
  "您即将开启的推送，与现存的count条推送时间冲突，是否确认开启？":
    "您即将开启的推送，与现存的<span style='color: red'>{count}</span>条推送时间冲突，是否确认开启？",
  推送类型: "推送类型",
  推送频率: "推送频率",
  推送内容: "推送内容",
  周日: "周日",
  周一: "周一",
  周二: "周二",
  周三: "周三",
  周四: "周四",
  周五: "周五",
  周六: "周六",
  推送看板: "推送看板",
  通知类: "通知类",
  推荐类: "推荐类",
  已发送过: "已发送过",
  未开启的自定义推送: "未开启的自定义推送",
  即将新建: "即将新建",
  查看系统推送: "查看系统推送",
  编辑系统推送: "编辑系统推送",
  创建系统推送: "创建系统推送",
  查看视图: "查看视图",
  发送时间: "发送时间",
  确认删除推送: "确认删除推送",
  "删除之后，不再展示该推送，是否确认删除？": "删除之后，不再展示该推送，是否确认删除？",
  删除推送: "删除推送",
  无效参数: "无效参数",
  "您创建的推送，标题里有无效参数:": "您创建的推送，标题里有无效参数:",
  "您创建的推送，内容里有无效参数：": "您创建的推送，内容里有无效参数：",
  确认提交: "确认提交",
  "确认后内容即将生效，你要继续吗？": "确认后内容即将生效，你要继续吗？",
  内容类型: "内容类型",
  发送时间和频次: "发送时间和频次",
  按间隔天数循环: "按间隔天数循环",
  按星期循环: "按星期循环",
  每: "每",
  天: "天",
  时间: "时间",
  保存并预览: "保存并预览",
  开启: "开启",
  关闭: "关闭",
  年: "年",
  月: "月",
  今天: "今天",
  星期: "星期",
  发送顺序: "发送顺序",
  "榜单推荐-U选爆品-30天销量": "榜单推荐-U选爆品-30天销量",
  "TAP榜单-申样": "TAP榜单-申样",
  "TAP榜单-收藏": "TAP榜单-收藏",
  "TAP榜单-加橱": "TAP榜单-加橱",
  "TAP榜单-30天销量": "TAP榜单-30天销量",
  "榜单推荐-运营主推tap本周": "榜单推荐-运营主推tap本周",
  "新品到货-热门样品": "新品到货-热门样品",
  等级专属: "等级专属",
  "榜单推荐-U选爆品-总销量": "榜单推荐-U选爆品-总销量",
  "榜单推荐-运营主推tap下周": "榜单推荐-运营主推tap下周",
  "榜单推荐-运营主推tap上周": "榜单推荐-运营主推tap上周",
  "新品到货-店铺评分": "新品到货-店铺评分",
  "新品到货-高佣率": "新品到货-高佣率",
  "新品到货-30天热销": "新品到货-30天热销",
  "新品到货-最新": "新品到货-最新"
};
