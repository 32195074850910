export default {
  Sample_Application: "样品申请",
  TAP_Sales_Report: "销售报表",
  Creator_Analysis: "达人分析",
  Product_Analysis: "商品分析",
  Seller_Analysis: "商家分析",
  Creator_Name_: "达人名称：",
  Please_enter: "请输入",
  Order_Creation_Date_: "订单创建日期：",
  Export_Report: "导出报表",
  Data_last_updated_on__mm___dd____yy____hh_mm___BKK_time_zone_:
    "数据更新于mm月dd日，yy HH:MM  (GMT +07:00)",
  Creator_with_Sales: "带货达人",
  Authorized_Creator: "授权达人",
  Unauthorized_Creator: "未授权达人",
  GMV: "GMV",
  Completed_GMV: "已完成GMV",
  Cancelled_GMV: "已取消GMV",
  Progressing_GMV: "进行中GMV",
  Completed_Sales: "已完成销量",
  Live_Streaming___Completed_GMV: "直播-已完成GMV",
  Video___Completed_GMV: "视频-已完成GMV",
  Showcase___Completed_GMV: "橱窗-已完成GMV",
  Product_Information: "商品信息",
  Product_ID_: "商品ID",
  Product_Price: "商品价格",
  Seller: "所属商家",
  Sales_Volume: "销量",
  Store_Code_: "店铺代码",
  Seller_Name_: "商家名称：",
  Seller_Information: "商家信息",
  Actions: "操作",
  View_Sales_Products: "查看销售商品",
  Sales_Products_of_Store_Named_xxxxx: "#店铺的销售商品",
  saleByCreator: "#的销售商品",
  orderNum: "订单数",
  "订单日期": "订单日期",
  "带货达人": "带货达人",
  "所属商家": "所属商家",
  "投流状态": "投流状态",
  "未投流": "未投流",
  "已投流": "已投流",
  商家PIC: '商家PIC',
  达人PIC: '达人PIC',
};
