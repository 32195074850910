const bag_icon = "https://file.uchoice.pro/public/img/240408/push.png";
const bag_icon_active = "https://file.uchoice.pro/public/img/240408/push_highlight.png";

const route: IRouteProps[] = [
  {
    name: "消息通知",
    meta: { icon: bag_icon, activeIcon: bag_icon_active },
    routes: [
      {
        path: "/push/system",
        name: "系统推送",
        component: "./push/system"
      },
      {
        path: "/push/system/edit",
        name: "系统推送",
        component: "./push/system/edit",
        hideInMenu: true
      },
      {
        path: "/push/manual",
        name: "自定义推送",
        component: "./push/manual"
      },
      {
        path: "/push/manual/edit",
        name: "自定义推送",
        component: "./push/manual/edit",
        hideInMenu: true
      }
    ]
  }
];

export default route;
