export default {
  通知方式: "Notification Method",
  APP推送: "APP Push",
  标题: "Title",
  消息内容: "Message Content",
  打开跳转至: "Open to Jump to",
  橱窗检测: "Showcase Inspection",
  数据榜单首页: "Data Leaderboard Homepage",
  AI话题首页: "AI Topic Homepage",
  AI定制: "AI Customization",
  待发送: "Pending Send",
  已发送: "Sent",
  发送失败: "Send Failed",
  推送时间: "Push Time",
  接收人: "Recipient",
  所有用户: "All Users",
  创建人: "Creator",
  查看自定义推送: "View Custom Push",
  编辑自定义推送: "Edit Custom Push",
  创建自定义推送: "Create Custom Push",
  全部用户: "All Users",
  指定用户: "Specified Users",
  定时发送: "Scheduled Send",
  即刻发送: "Immediate Send",
  内容分类: "Content Category",
  通知: "Notification",
  推荐: "Recommendation",
  频次: "Frequency",
  仅一次: "Only Once",
  每n天一次: "Once Every {day} Days",
  通知名称: "Notification Name",
  触发节点: "Trigger Node",
  待审核转为待发货: "Pending Review to Pending Shipment",
  待发货转为已发货: "Pending Shipment to Shipped",
  待发货转为待履约: "Pending Shipment to Pending Fulfillment",
  橱窗账号登录状态异常: "Showcase Account Login Status Abnormal",
  存在橱窗高佣待替换商品: "High Commission Items Waiting for Replacement in Showcase",
  没有橱窗登录过的用户: "Users who have not logged into the showcase before",
  没有开启自动替换高佣的用户: "Users who have not activate automatic button",
  未点击过加入推广计划按钮的用户: "Users who have not clicked the Join Promotion Plan button",
  "榜单推荐-U选爆品": "Leaderboard Recommendation - U Select Popular Items",
  "榜单推荐-达人带货榜": "Leaderboard Recommendation - Influencer Merchandise",
  "榜单推荐-U选高佣": "Leaderboard Recommendation - U Select High Commission",
  "榜单推荐-AI热点主题榜": "Leaderboard Recommendation - AI Hot Topic",
  "榜单推荐-持续好货榜": "Leaderboard Recommendation - Continuous Good Items",
  "榜单推荐-达人涨粉榜": "Leaderboard Recommendation - Influencer Follower Growth",
  "榜单推荐-日销商品榜": "Leaderboard Recommendation - Daily Sales Items",
  "榜单推荐-运营主推tap": "Leaderboard Recommendation - Main Operation Tap",
  所有用户包括访客: "All Users Including Visitors",
  申样用户: "Sample Application Users",
  橱窗授权用户: "Showcase Authorized Users",
  已登录过橱窗的用户: "Users who have logged into the showcase",
  默认跳转: "Default Jump",
  指定H5页面: "Specified H5 Page",
  APP内页面: "In-App Page",
  单商品: "Single Item",
  待发货订单列表: "Pending Shipment Order List",
  待履约订单列表: "Pending Fulfillment Order List",
  橱窗检测账号列表: "Showcase Inspection Account List",
  高佣可替换商品列表: "High Commission Replaceable Item List",
  APP指定商品集落地页: "APP Specified Item Set Landing Page",
  APP数据持续好货榜: "APP Data Continuous Good Items Leaderboard",
  编辑内容: "Edit Content",
  确认关闭推送消息: "Confirm Close Push Message",
  确认修改推送内容: "Confirm Modify Push Content",
  "确认后内容将生效，你还要继续吗？":
    "<span style='color: red'>After confirmation, the content will take effect,</span> do you want to continue?",
  正在编辑推送内容: "Editing Push Content",
  推送标题: "Push Title",
  请输入至少五个字符: "Please Enter at Least Five Characters",
  "确认后将不再推送此条消息，你还要继续吗？":
    "<span style='color: red'>After confirmation, this message will no longer be pushed,</span> do you want to continue?",
  关闭推送: "Close Push",
  开启推送: "Enable Push",
  "TAP榜单-免费样品": "uChoice Rank - Free Sample Ranking",
  "TAP榜单-收藏夹": "uChoice Rank -Wishlist Ranking",
  "TAP榜单-添加橱窗": "uChoice Rank -TikTok Showcase Ranking",
  "TAP榜单- 30天畅销榜单": "uChoice Rank -Best Sellers Ranking",
  当天有订单审核被拒且无通过的: "Order review rejected with no approvals on the same day",
  当天有账号升级变动: "Account upgrade change on the same day",
  L0账号触发给样机制: "L0 account triggers the sample distribution mechanism",
  新品到货: "New product arrival",
  推广记录建议: "Promotion record suggestion",
  每日6款推荐: "Daily 6 product recommendations",
  L0等级用户: "L0 level user",
  过去30天有推广记录的用户: "Users with promotion records in the past 30 days",
  有授权记录的用户: "Users with authorization records",
  过去7天有推广记录的用户: "Users' promotion record in the last 7 days",
  过去7天无推广记录的用户: "Users without promotion record in 7 days",
  所有已登录用户: "All logged - in users",
  "推广记录建议--近7天有数据": "Promo. record -Data available in the last 7 days",
  "推广记录建议--近7天无数据": "Promo. record -No data in the last 7 days",
  "爆单提醒-用户当天销量大于30单": "Order Reminder - Daily sales over 30",
  "TikTok数据榜单-店铺榜": "TikTok Rank - Shop Rank",
  "TikTok数据榜单-潜力爆品榜": "TikTok Rank - Potential Hot Product Rank",
  推送状态: "Push Status",
  已开启: "Enabled",
  即将开启: "Pending Activation",
  每周一: "Every Monday",
  每周二: "Every Tuesday",
  每周三: "Every Wednesday",
  每周四: "Every Thursday",
  每周五: "Every Friday",
  每周六: "Every Saturday",
  每周日: "Every Sunday",
  推送名称: "Push Name",
  等级用户: "Tiered Users",
  跳转页面: "Redirect Page",
  时间重合: "Time Conflict",
  确认开启: "Confirm Activation",
  "您即将开启的推送，与现存的count条推送时间冲突，是否确认开启？":
    "The push conflicts with <span style='color: red'>{count}</span> existing pushes. Confirm activation?",
  推送类型: "Push Type",
  推送频率: "Push Frequency",
  推送内容: "Push Content",
  周日: "Sunday",
  周一: "Monday",
  周二: "Tuesday",
  周三: "Wednesday",
  周四: "Thursday",
  周五: "Friday",
  周六: "Saturday",
  推送看板: "Push Dashboard",
  通知类: "Notifications",
  推荐类: "Recommendations",
  已发送过: "Sent History",
  未开启的自定义推送: "Inactive Custom Push",
  即将新建: "New Creation",
  查看系统推送: "View System Push",
  编辑系统推送: "Edit System Push",
  创建系统推送: "Create System Push",
  查看视图: "View Mode",
  发送时间: "Delivery Time",
  "每周${record.sendFrequency}": "Every ${record.sendFrequency} weeks",
  确认删除推送: "Confirm Deletion",
  "删除之后，不再展示该推送，是否确认删除？": "This push will be permanently deleted. Confirm?",
  删除推送: "Delete Push",
  无效参数: "Invalid Parameter",
  "您创建的推送，标题里有无效参数:": "Invalid title parameters:",
  "您创建的推送，内容里有无效参数：": "Invalid content parameters:",
  确认提交: "Confirm Submission",
  "确认后内容即将生效，你要继续吗？": "Changes will take effect immediately. Proceed?",
  内容类型: "Content Type",
  发送时间和频次: "Delivery Schedule",
  按间隔天数循环: "Recur by Day Interval",
  按星期循环: "Recur Weekly",
  每: "Every",
  天: "Day(s)",
  时间: "Time",
  保存并预览: "Save & Preview",
  开启: "Enable",
  关闭: "Disable",
  年: "Year",
  月: "Month",
  今天: "Today",
  星期: "Week",
  发送顺序: "Sending order",
  "榜单推荐-U选爆品-30天销量": "recommended list - selected hot products",
  "TAP榜单-申样": "uchoice ranking - free sample ranking",
  "TAP榜单-收藏": "uchoice ranking-wishlist ranking",
  "TAP榜单-加橱": "uchoice ranking - showcase ranking",
  "TAP榜单-30天销量": "uchoice ranking - 30-day sales",
  "榜单推荐-运营主推tap本周": "recommended list - by operator this week",
  "新品到货-热门样品": "new product arrival - hot sample",
  等级专属: "level exclusive",
  "榜单推荐-U选爆品-总销量": "recommended list-selected hot products",
  "榜单推荐-运营主推tap下周": "recommended list - by operator next week",
  "榜单推荐-运营主推tap上周": "recommended list - by operator next week",
  "新品到货-店铺评分": "new product arrival-store rating",
  "新品到货-高佣率": "new product arrival-high commission rate",
  "新品到货-30天热销": "new product arrival - 30day hot sale",
  "新品到货-最新": "new product arrival - latest"
};
