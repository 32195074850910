// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/root/workspace/uchoice-ops_9dTV/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/root/workspace/uchoice-ops_9dTV/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "redirect": "/rootRedirect",
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/rootRedirect",
        "name": "rootRedirect",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__rootRedirect' */'/root/workspace/uchoice-ops_9dTV/src/pages/rootRedirect'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/home",
        "name": "首页",
        "meta": {
          "icon": "https://file-th.ypkclient.com/system/20230915/2779aef55e5b4da797a93fa24e32b3cd.png",
          "activeIcon": "https://file-th.ypkclient.com/system/20230915/d64b83a82e5b4dd7940043fc72b441f1.png"
        },
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home' */'/root/workspace/uchoice-ops_9dTV/src/pages/home'), loading: LoadingComponent}),
        "access": "tapMasterOrSuperAdmin",
        "exact": true
      },
      {
        "path": "/performance",
        "name": "绩效管理",
        "meta": {
          "icon": "https://file.uchoice.pro/public/img/240722/1.png",
          "activeIcon": "https://file.uchoice.pro/public/img/240722/3.png"
        },
        "access": "picPerformance",
        "routes": [
          {
            "path": "/performance/dashboard",
            "name": "PIC绩效",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__performance__dashboard' */'/root/workspace/uchoice-ops_9dTV/src/pages/performance/dashboard'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/performance/preview/:follower",
            "name": "数据概览",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__performance__preview' */'/root/workspace/uchoice-ops_9dTV/src/pages/performance/preview'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/operation",
        "name": "运营管理",
        "meta": {
          "icon": "https://file.uchoice.pro/public/img/240722/4.png",
          "activeIcon": "https://file.uchoice.pro/public/img/240722/2.png"
        },
        "routes": [
          {
            "path": "/operation/homeBanner",
            "name": "banner配置",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__homeBanner' */'/root/workspace/uchoice-ops_9dTV/src/pages/operation/homeBanner'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/operation/homeBanner/add",
            "name": "添加",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__homeBanner__add' */'/root/workspace/uchoice-ops_9dTV/src/pages/operation/homeBanner/add'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/operation/homeBanner/edit/:id",
            "name": "编辑",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__homeBanner__add' */'/root/workspace/uchoice-ops_9dTV/src/pages/operation/homeBanner/add'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/operation/homeBanner/detail/:id",
            "name": "详情",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__homeBanner__add' */'/root/workspace/uchoice-ops_9dTV/src/pages/operation/homeBanner/add'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/operation/customPage",
            "name": "动态页配置",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__customPage' */'/root/workspace/uchoice-ops_9dTV/src/pages/operation/customPage'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/operation/customPage/add",
            "name": "新增动态页",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__customPage__add' */'/root/workspace/uchoice-ops_9dTV/src/pages/operation/customPage/add'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/operation/customPage/detail/:id",
            "name": "编辑动态页",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__customPage__add' */'/root/workspace/uchoice-ops_9dTV/src/pages/operation/customPage/add'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/operation/waterfallFlow",
            "name": "瀑布流配置",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__waterfallFlow' */'/root/workspace/uchoice-ops_9dTV/src/pages/operation/waterfallFlow'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/operation/waterfallFlow/add",
            "name": "添加",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__waterfallFlow__add' */'/root/workspace/uchoice-ops_9dTV/src/pages/operation/waterfallFlow/add'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/operation/waterfallFlow/edit/:id",
            "name": "添加",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__waterfallFlow__add' */'/root/workspace/uchoice-ops_9dTV/src/pages/operation/waterfallFlow/add'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/operation/waterfallFlow/detail/:id",
            "name": "添加",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__waterfallFlow__add' */'/root/workspace/uchoice-ops_9dTV/src/pages/operation/waterfallFlow/add'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/operation/leaderboard",
            "name": "排行榜配置",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__leaderBoard' */'/root/workspace/uchoice-ops_9dTV/src/pages/operation/leaderBoard'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/operation/leaderboard/add",
            "name": "排行榜新增",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__leaderBoard__add' */'/root/workspace/uchoice-ops_9dTV/src/pages/operation/leaderBoard/add'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/operation/leaderboard/edit/:id",
            "name": "排行榜编辑",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__leaderBoard__add' */'/root/workspace/uchoice-ops_9dTV/src/pages/operation/leaderBoard/add'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/operation/newer/collections",
            "name": "新人样品池配置",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__newer__collections' */'/root/workspace/uchoice-ops_9dTV/src/pages/operation/newer/collections'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/operation/SampleApplyThreshold",
            "name": "uChoice&TT样品申请门槛设置",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__sampleApplyThreshold' */'/root/workspace/uchoice-ops_9dTV/src/pages/operation/sampleApplyThreshold'), loading: LoadingComponent}),
            "hideInMenu": false,
            "exact": true
          },
          {
            "path": "/operation/inflowManangement/index",
            "name": "投流管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__inflowManangement__index' */'/root/workspace/uchoice-ops_9dTV/src/pages/operation/inflowManangement/index'), loading: LoadingComponent}),
            "access": "canOporation",
            "exact": true
          }
        ]
      },
      {
        "path": "/user-manage",
        "name": "用户管理",
        "meta": {
          "icon": "https://file-th.ypkclient.com/system/20230915/16e33f578d3e42248903223e72623e4a.png",
          "activeIcon": "https://file-th.ypkclient.com/system/20230915/3b014a33cc044d8fb2fad601dccf28e2.png"
        },
        "routes": [
          {
            "path": "/user-manage/talent-gonghai",
            "name": "达人公海",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user-manage__talent-gonghai' */'/root/workspace/uchoice-ops_9dTV/src/pages/user-manage/talent-gonghai'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user-manage/uchoice-user",
            "name": "跟进达人",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user-manage__uchoice-user' */'/root/workspace/uchoice-ops_9dTV/src/pages/user-manage/uchoice-user'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user-manage/uchoice-user-video",
            "name": "用户视频列表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user-manage__uchoice-user-video' */'/root/workspace/uchoice-ops_9dTV/src/pages/user-manage/uchoice-user-video'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/user-manage/tag-manage",
            "name": "标签管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user-manage__tag-manage' */'/root/workspace/uchoice-ops_9dTV/src/pages/user-manage/tag-manage'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user-manage/anchor-link",
            "name": "达人链接管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user-manage__anchor-link' */'/root/workspace/uchoice-ops_9dTV/src/pages/user-manage/anchor-link'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user-manage/anchor-link/detail",
            "name": "达人链接管理详情",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user-manage__anchor-link__detail' */'/root/workspace/uchoice-ops_9dTV/src/pages/user-manage/anchor-link/detail'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/product-manage",
        "name": "商品管理",
        "meta": {
          "icon": "https://file-th.ypkclient.com/system/20230915/131aafd7c8904df1ac145cd29adf20c8.png",
          "activeIcon": "https://file-th.ypkclient.com/system/20230915/77f257b87ef645b2979c186e98e2a5c1.png"
        },
        "routes": [
          {
            "path": "/product-manage/tt-products/normal",
            "name": "普通活动",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__tt-products__index' */'/root/workspace/uchoice-ops_9dTV/src/pages/tt-products/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/product-manage/tt-products/normal/source/:id",
            "name": "素材编辑",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__tt-products__SourcePage' */'/root/workspace/uchoice-ops_9dTV/src/pages/tt-products/SourcePage'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/product-manage/tt-products/star",
            "name": "明星活动",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__tt-products__star' */'/root/workspace/uchoice-ops_9dTV/src/pages/tt-products/star'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/product-manage/tt-products/star/source/:id",
            "name": "素材编辑",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__tt-products__SourcePage' */'/root/workspace/uchoice-ops_9dTV/src/pages/tt-products/SourcePage'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/product-manage/tt-collections",
            "name": "商品集",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__collection' */'/root/workspace/uchoice-ops_9dTV/src/pages/collection'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/login",
        "name": "登录",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login' */'/root/workspace/uchoice-ops_9dTV/src/pages/login'), loading: LoadingComponent}),
        "layout": false,
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/orderManagement",
        "name": "样品申请",
        "meta": {
          "icon": "https://file-th.ypkclient.com/system/20230915/3cb97a40f90d4e6db666d4928c5ccf2b.png",
          "activeIcon": "https://file-th.ypkclient.com/system/20230915/60a19cd2d09740f0883a35146f38a818.png"
        },
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orderManagement__sampleApp' */'/root/workspace/uchoice-ops_9dTV/src/pages/orderManagement/sampleApp'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/orderManagement/sampleApp/detail/:id",
        "name": "sampleApplicationDetail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orderManagement__detail' */'/root/workspace/uchoice-ops_9dTV/src/pages/orderManagement/detail'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/salesData",
        "name": "销售报表",
        "meta": {
          "icon": "https://file-th.ypkclient.com/system/20230915/184d278afc0047c18a9bb44b58aedf76.png",
          "activeIcon": "https://file-th.ypkclient.com/system/20230915/7a60555e3926479e898f6608acda6929.png"
        },
        "routes": [
          {
            "path": "/salesData/creator",
            "name": "达人分析",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__salesData__creator' */'/root/workspace/uchoice-ops_9dTV/src/pages/salesData/creator'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/salesData/goods",
            "name": "商品分析",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__salesData__goods' */'/root/workspace/uchoice-ops_9dTV/src/pages/salesData/goods'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/salesData/shop",
            "name": "商家分析",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__salesData__shop' */'/root/workspace/uchoice-ops_9dTV/src/pages/salesData/shop'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/salesData/videoFinishAgreement",
            "name": "视频履约分析",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__salesData__videoFinishAgreement' */'/root/workspace/uchoice-ops_9dTV/src/pages/salesData/videoFinishAgreement'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/sheet/:type",
        "name": "uChoiceSheet",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sheet' */'/root/workspace/uchoice-ops_9dTV/src/pages/sheet'), loading: LoadingComponent}),
        "layout": false,
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/supplierManagement",
        "name": "供应链管理",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__supplierManagement' */'/root/workspace/uchoice-ops_9dTV/src/pages/supplierManagement'), loading: LoadingComponent}),
        "meta": {
          "icon": "https://youpik-test.oss-ap-southeast-1.aliyuncs.com/system/20230915/db801ffc712c4d718a693d68dd779664.png",
          "activeIcon": "https://youpik-test.oss-ap-southeast-1.aliyuncs.com/system/20230915/e2ed8cdc2ab243828a8ce7e12e6bae04.png"
        },
        "exact": true
      },
      {
        "path": "/supplierManagement/add",
        "name": "新增",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__supplierManagement__supplierAdd' */'/root/workspace/uchoice-ops_9dTV/src/pages/supplierManagement/supplierAdd'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/supplierManagement/edit/:supplierId",
        "name": "编辑",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__supplierManagement__supplierAdd' */'/root/workspace/uchoice-ops_9dTV/src/pages/supplierManagement/supplierAdd'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/supplierManagement/shop/:supplierid&:name",
        "name": "店铺",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__supplierManagement__shop' */'/root/workspace/uchoice-ops_9dTV/src/pages/supplierManagement/shop'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/supplierManagement/shop/pendingStore",
        "name": "待绑定店铺",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__supplierManagement__shop__pendingStore' */'/root/workspace/uchoice-ops_9dTV/src/pages/supplierManagement/shop/pendingStore'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "name": "消息通知",
        "meta": {
          "icon": "https://file.uchoice.pro/public/img/240408/push.png",
          "activeIcon": "https://file.uchoice.pro/public/img/240408/push_highlight.png"
        },
        "routes": [
          {
            "path": "/push/system",
            "name": "系统推送",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__push__system' */'/root/workspace/uchoice-ops_9dTV/src/pages/push/system'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/push/system/edit",
            "name": "系统推送",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__push__system__edit' */'/root/workspace/uchoice-ops_9dTV/src/pages/push/system/edit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/push/manual",
            "name": "自定义推送",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__push__manual' */'/root/workspace/uchoice-ops_9dTV/src/pages/push/manual'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/push/manual/edit",
            "name": "自定义推送",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__push__manual__edit' */'/root/workspace/uchoice-ops_9dTV/src/pages/push/manual/edit'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "layout": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/root/workspace/uchoice-ops_9dTV/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
